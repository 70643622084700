<template>
  <div>
    <b-card>
      <h3
        style="color: rgb(115, 103, 240);"
        class="mb-2"
      >
        คำขอดูแฟ้มข้อมูล
      </h3>
      <div>
        <hr>
        <h4
          style="text-align: center;font-weight: bold;"
          class="mt-2"
        >
          วันที่ส่งคำขอ
        </h4>
        <b-row>
          <b-col cols="4" />
          <b-col cols="4">
            <b-form-datepicker
              id="datepicker-invalid"
              v-model="dateNow"
              class="mb-2"
              style="text-align: center;"
            />
          </b-col>
          <b-col cols="4" />
        </b-row>
        <hr>
      </div>
      <b-row class="mt-1">
        <b-col
          md="2"
          sm="4"
          class="my-1"
        />
        <b-col
          md="4"
          sm="8"
          class="my-1"
        />
        <b-col
          md="6"
          class="my-1"
        >
          <b-form-group
            label="ค้นหา"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="12"
        >
          <b-table
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            selectable
            :select-mode="selectMode"
            @filtered="onFiltered"
            @row-selected="onRowSelected"
          >
            <!-- Status -->
            <template #cell(approval)="data">
              <b-badge :variant="approval[1][data.value]">
                <feather-icon
                  :icon="data.item.approval === true ? 'CheckIcon' : 'LoaderIcon'"
                  class="mr-25"
                />
                {{ approval[0][data.value] }}
              </b-badge>
            </template>
            <template #cell(username)="data">
              <b-badge
                variant="light-dark"
                class="badge-glow"
              >
                <feather-icon
                  icon="UserIcon"
                  class="mr-25"
                />
                <span> {{ data.item.username }}</span>
              </b-badge>
            </template>
            <template #cell(startDate)="data">
              <b-badge
                variant="light-dark"
                class="badge-glow"
              >
                <span> {{ data.item.startDate }}</span>
              </b-badge>
            </template>
            <template #cell(reason)="data">
              <b-badge
                variant="light-dark"
                class="badge-glow"
              >
                <span> {{ data.item.reason }}</span>
              </b-badge>
            </template>
            <template #cell(expireDate)="data">
              <b-badge
                variant="light-danger"
                class="badge-glow"
              >
                <feather-icon
                  icon="AlertTriangleIcon"
                  class="mr-25"
                />
                <span> {{ data.item.expireDate }}</span>
              </b-badge>
            </template>
            <!-- ปุ่มดูแฟ้ม -->
            <template #cell(file)="row">
              <b-button
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                v-b-modal.modal-dark
                size="sm"
                :variant="buttonVariant(row.item)"
                :disabled="buttonDisable(row.item)"
                @click="info(row.item, row.index, $event.target)"
              >
                <feather-icon icon="FolderPlusIcon" />
                {{ textButton }}
              </b-button>
            </template>
            <template #cell(ExtendTime)="row">
              <b-button
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                v-b-modal.modal-primary
                variant="danger"
                class="btn-icon rounded-circle"
                :disabled="buttonDisable1(row.item)"
                @click="infoExtendTime(row.item, row.index, $event.target)"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <div>
        <b-modal
          v-if="items.length > 0"
          id="modal-dark"
          ok-only
          hide-footer
          ok-variant="danger"
          ok-title="CLOSE"
          modal-class="modal-dark"
          size="xl"
          centered
          title="HIE PATIENT"
        >
          <b-table
            hover
            responsive="sm"
            :fields="fieldPatients"
            :items="items[keepIndex].patients"
            :head-variant="headVariant"
          >
            <!-- <template #cell(hn)="data">
                <h5 style="color: rgb(177 67 67);">{{ data.value }}</h5>
            </template> -->
            <template #cell(approval)="data">
              <b-badge :variant="approval2[1][data.value]">
                {{ approval2[0][data.value] }}
              </b-badge>
            </template>
            <template #cell(hn)="data">
              <b-badge
                variant="light-dark"
                class="badge-glow"
              >
                <span>{{ data.item.hn }}</span>
              </b-badge>
            </template>
            <template #cell(born)="data">
              <b-badge
                variant="light-dark"
                class="badge-glow"
              >
                <span>{{ data.item.born }}</span>
              </b-badge>
            </template>
            <template #cell(fullname)="data">
              <b-badge
                variant="light-dark"
                class="badge-glow"
              >
                <span>{{ data.item.ttl }} {{ data.item.name }} {{ data.item.lName }}</span>
              </b-badge>
            </template>
            <!-- กดโอน -->
            <template #cell(AccessRights)="row">
              <b-button
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                v-b-modal.modal-dark
                variant="gradient-success"
                class="btn-icon rounded-circle"
                size="sm"
                :disabled="buttonDisable(row.item)"
                @click="infoPatients(row.item, row.index, $event.target)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
              &nbsp;&nbsp;&nbsp;
              <b-button
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                v-b-modal.modal-dark
                variant="gradient-danger"
                class="btn-icon rounded-circle ml-2"
                size="sm"
                :disabled="buttonDisable(row.item)"
                @click="rejectInfoPatients(row.item, row.index, $event.target)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </template>
            <!-- กดโอน -->
            <!-- <template #cell(AccessRights)="row">
              <b-button
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                v-b-modal.modal-dark
                size="sm"
                :variant="buttonVariant(row.item)"
                :disabled="buttonDisable(row.item)"
                @click="infoPatients(row.item, row.index, $event.target)"
              >
                เลือกแฟ้ม
              </b-button>
            </template> -->
          </b-table>
          <br>
        </b-modal>
        <b-modal
          v-if="selectedDate != []"
          id="modal-primary"
          hide-footer
          ok-only
          ok-title="CLOSE"
          ok-variant="primary"
          modal-class="modal-primary"
          centered
          title="ขยายเวลาการดูแฟ้ม"
        >
          <b-row>
            <b-col
              cols="5"
              class="mt-1"
              style="font-weight: bold;color:#f81313;"
            >
              <h4> วันที่หมดอายุตอนนี้ </h4>
            </b-col>
            <b-col
              cols="7"
              class="mt-1"
            >
              <h4 style="font-weight: bold;color:rgb(159 50 50);">
                {{ selectedDate.expireDate }}
              </h4>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col
              cols="4"
              class="mt-1"
              style="font-weight: bold;"
            >
              <h4> ขยายถึงวันที่ </h4>
            </b-col>
            <b-col
              cols="8"
              class="mb-1"
            >
              <b-form-datepicker
                v-model="ExtendTimeDate"
              />
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col cols="4" />
            <b-col
              cols="4"
              class="text-center"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="ExtendTime_Save()"
              >
                บันทึก
              </b-button>
            </b-col>
            <b-col cols="4" />
          </b-row>
          <br>
        </b-modal>
      </div>
    </b-card></div>
</template>

<script>
import {
  BModal, VBModal, BButton, BTable, BBadge, BFormDatepicker, BRow, BCol, BFormGroup, BCard, BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BModal,
    BButton,
    BFormDatepicker,
    BTable,
    BRow,
    BCol,
    BBadge,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCard,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      testActiveSync: false,
      selected: [],
      selected1: [],
      items2: [],
      dataName: true,
      disable: true,
      textButton: 'ข้อมูล',
      selectMode: 'single',
      headVariant: 'dark',
      perPage: 100,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      keepUsername: '',
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      dateNow: new Date(),
      dateGETrequire: new Date(),
      ExtendTimeDate: null,
      selectedDate: [],
      Keep_ExtendTime: false,
      keep_status_substr: false,
      statusConfirm: false,
      fields: [
        {
          key: 'username', label: 'ผู้ขอข้อมูล', sortable: true,
        },
        {
          key: 'startDate', label: 'วันที่ส่งคำขอ', sortable: true,
        },
        {
          key: 'reason', label: 'เหตุผล',
        },
        {
          key: 'expireDate', label: 'วันที่หมดอายุ', sortable: true,
        },
        {
          key: 'approval', label: 'สถานะ', sortable: true,
        },
        'file',
        'ExtendTime',
      ],
      fieldPatients: [
        {
          key: 'hn', label: 'HN',
        },
        'fullname',
        {
          key: 'born', label: 'born',
        },
        {
          key: 'approval', label: 'Status',
        },
        'AccessRights',
      ],
      items: [],
      approval: [{
        false: 'รอดำเนินการ', true: 'ดำเนินการแล้ว',
      },
      {
        false: 'light-danger', true: 'light-success',
      }],
      approval2: [{
        false: 'ยังไม่ได้โอนแฟ้ม', true: 'โอนแฟ้มเรียบร้อยแล้ว',
      },
      {
        false: 'light-danger', true: 'light-success',
      }],
      keepDatefirst: '',
      keepIndex: 0,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    dateNow() {
      // console.log('dateNow:', this.dateNow)
      // this.dateGETrequire = `${this.dateNow.getMonth()}`
      // // console.log('dateGETrequire:', this.dateGETrequire.getMonth())
      this.getRequire_hie()
    },
    ExtendTimeDate() {
      // console.log('ExtendTimeDate:', this.ExtendTimeDate)
    },
  },
  async mounted() {
    this.dateNow = this.DateTimeDatevalue(this.dateNow)
    await this.getRequire_hie()
    await this.substrRequire_hie()
    this.keepUsername = JSON.parse(localStorage.getItem('userData')).username
  },
  methods: {
    async ExtendTime_Save() {
      await this.$http({
        url: 'api/HIE/extendExpireDate',
        method: 'post',
        data: {
          code: this.selectedDate.code,
          UpdateBy: this.keepUsername,
          Date: this.ExtendTimeDate,
        },
      }).then(response => {
        // console.log(response.data)
        this.Keep_ExtendTime = response.data
      })
      await this.Keep_ExtendTime_methods()
    },
    Keep_ExtendTime_methods() {
      if (this.Keep_ExtendTime === true) {
        this.success()
      } else {
        this.error()
      }
    },
    async success() {
      await this.$swal({
        title: 'ขยายเวลาสำเร็จ',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
      await this.$router.go()
    },
    error() {
      this.$swal({
        title: 'ขยายเวลาไม่สำเร็จ',
        text: ' กรุณาตรวจสอบข้อมูลหรือขยายเวลาใหม่',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    buttonDisable(item) {
      switch (item.approval) {
        case false:
          return false
        default:
          return false
      }
    },
    buttonDisable1(item) {
      switch (item.approval) {
        case false:
          return true
        default:
          return false
      }
    },
    buttonVariant(item) {
      console.log(item.approval)
      switch (item.approval) {
        case false: // อนุมัติ
          return 'danger'
        default:// ใกล้หมดอายุ
          return 'success'
      }
    },
    onRowSelected(items) {
      this.selected = items
    },
    totalRow() {
      this.totalRows = this.items.length
    },
    async info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      // console.log('item, index', item, index)
      this.selected1 = item
      // console.log('sdasdsads', this.selected1)
      this.dataName = false
      this.keepIndex = index
    },
    async infoExtendTime(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      // console.log('item, index', item)
      this.selectedDate = item
    },
    async infoPatients(item, index, button) {
      // ล้างค่าใน vuex
      // console.log('check plz', item, index, button)
      await this.confirmText(item, index, button)
      await this.$store.commit('hieStateImage/clearData')
    },
    async rejectInfoPatients(item, index, button) {
      await this.confirmRejectText(item, index, button)
      // console.log(item)
    },
    sendViewer() {
      if (this.selected1.approval) {
        this.$router.push({ name: 'viewerUpdate', params: this.selected1 })
      } else {
        this.$router.push({ name: 'viewer', params: this.selected1 })
      }
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async getRequire_hie() {
      await this.$http({
        url: `api/HIE/getRequsetsByDate/${this.dateNow}`,
        method: 'get',
        data: {
        },
      }).then(response => {
        // console.log(response.data)
        this.items = response.data
      })
      await this.totalRow()
      await this.substrRequire_hie()
      setTimeout(() => {
        this.getRequire_hie()
      }, 300000)
    },
    substrRequire_hie() {
      let index = 0
      for (index; index < this.items.length; index += 1) {
        this.items[index].startDate = this.items[index].startDate.substring(0, 10)
        this.items[index].expireDate = this.items[index].expireDate.substring(0, 10)
        let index2 = 0
        for (index2; index2 < this.items[index].patients.length; index2 += 1) {
          this.items[index].patients[index2].born = this.items[index].patients[index2].born.substring(0, 10)
        }
      }
    },
    DateTimeDatevalue(WrittenDate) {
      if (WrittenDate !== null) {
        const today = WrittenDate
        const date = `${today.getFullYear()}-${(`0${today.getMonth() + 1}`).substr(-2)}-${(`0${today.getDate()}`).substr(-2)}`
        const dateTime = `${date}`
        return dateTime
      }
      return null
    },
    async confirmText(item, index, button) {
      await this.$swal({
        title: 'คุณแน่ใจไหม?',
        text: 'ในการให้สิทธิ์การดูแฟ้มข้อมูลทั้งหมด',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่, ฉันแน่ใจ!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.infoModal.title = `Row index: ${index}`
          this.infoModal.content = JSON.stringify(item, null, 2)
          this.$root.$emit('bv::show::modal', this.infoModal.id, button)
          const { code } = this.selected1
          this.selected1 = item
          this.statusConfirm = true
          this.saveApproveFlag(code)
          this.$swal({
            icon: 'success',
            title: 'สำเร็จ!',
            text: 'ให้สิทธิ์การดูแฟ้มข้อมูลทั้งหมด.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          // console.log('this.statusConfirm', this.statusConfirm)
          if (this.statusConfirm === true) {
            // this.sendViewer()
            this.statusConfirm = null
          }
          this.$router.go()
        }
      })
    },
    async confirmRejectText(item, index, button) {
      await this.$swal({
        title: 'คุณแน่ใจไหม?',
        text: 'ในการปฏิเสธการสิทธิ์การดูแฟ้มข้อมูลทั้งหมด',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่, ฉันแน่ใจ!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.infoModal.title = `Row index: ${index}`
          this.infoModal.content = JSON.stringify(item, null, 2)
          this.$root.$emit('bv::show::modal', this.infoModal.id, button)
          const { code } = this.selected1
          this.selected1 = item
          this.statusConfirm = true
          this.disabledApproveFlag(code)
          this.$swal({
            icon: 'success',
            title: 'สำเร็จ!',
            text: 'ปฏิเสธสิทธิ์การดูแฟ้มข้อมูลทั้งหมด.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          // console.log('this.statusConfirm', this.statusConfirm)
          if (this.statusConfirm === true) {
            this.statusConfirm = null
          }
          this.$router.go()
        }
      })
    },
    saveApproveFlag(uidCode) {
      this.$http({
        url: 'api/user/SaveApproveFlag',
        method: 'POST',
        data: {
          Code: uidCode,
        },
      })
    },
    disabledApproveFlag(uidCode) {
      this.$http({
        url: 'api/user/DisabledApproveFlag',
        method: 'POST',
        data: {
          Code: uidCode,
        },
      })
    },
  },
}
</script>
